import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Journey } from "../../utils/mapJourneysData";
import { fetchJourneys } from '../../utils/fetchJourneys';
import { colors } from '../../utils/colors';

import * as AlternativeButtons from '../Controls/AlternativeButtons';
import JourneyLegCard from './JourneyLegCard';
import Transfer from "./Transfer";
import KPIsContainer from "./KPIsContainer";
import PageHeader from '../PageHeader';
import FeedbackCard from '../FeedbackCard';


const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 48px;
  gap: 32px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px 16px; // Add 24px padding at the top and bottom
  background: ${colors.white};
  border-bottom: 2px solid ${colors.keyHover};
`;

const LegsContainer = styled('div')`
`

const TransferContainer = styled('div')`
  padding: 16px 16px 16px 24px;
  margin-left: 24px;
  position: relative;
  display: flex;
  grid-row-gap: 14px;
  flex-direction: column;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background: repeating-linear-gradient(${colors.grey600}, ${colors.grey600} 6px, transparent 6px, transparent 12px);
  }
`

const AlternativesContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
`


type JourneyDetailsScreenProps = {
  journey: Journey;
}

// Define the fetchLaterDepartures function
const fetchLaterDepartures = async (
  originId: string, 
  destinationId: string, 
  plannedDeparture: string, 
  departure: string,
  onJourneysFetched: (journeys: any[], newEarlierThan: string | null, newLaterThan: string | null, newDepartureTime: string) => void, // Pass newDepartureTime here
  onError: (message: string) => void,
  onLoadingChange: (isLoading: boolean) => void
) => {
  // Determine the correct departure time
  const departureTimeStr = departure || plannedDeparture; // Use `departure` if available, otherwise use `plannedDeparture`

  // Parse the departureTime and add 1 minute
  const departureTime = new Date(departureTimeStr);
  departureTime.setMinutes(departureTime.getMinutes() + 1);

  // Format the updated departureTime as an ISO string (you can format it based on the API requirements)
  const newDepartureTime = departureTime.toISOString();

  // Call fetchJourneys with the correct parameters
  fetchJourneys({
    type: 'initial',
    originId: originId,
    destinationId: destinationId,
    date: newDepartureTime,
    tripType: 'departure', 
    options: {}, // If you have options, you can pass them here
    earlierThan: null, // No earlier journeys needed
    laterThan: null,  // No later journeys needed
    onJourneysFetched: (journeys, newEarlierThan, newLaterThan) => onJourneysFetched(journeys, newEarlierThan, newLaterThan, newDepartureTime),  // Pass the newDepartureTime to onJourneysFetched
    onError: onError,  // Callback for error handling
    onLoadingChange: onLoadingChange,  // Callback to show/hide loading indicator
  });
}

// Define the fetchLaterDepartures function
const fetchEarlierArrivals = async (
  originId: string, 
  destinationId: string, 
  plannedArrival: string, 
  arrival: string,
  onJourneysFetched: (journeys: any[], newEarlierThan: string | null, newLaterThan: string | null, newArrivalTime: string) => void, // Pass newArrivalTime here
  onError: (message: string) => void,
  onLoadingChange: (isLoading: boolean) => void
) => {
  // Determine the correct departure time
  const arrivalTimeStr = arrival || plannedArrival; // Use `arrival` if available, otherwise use `plannedArrival`

  // Parse the departureTime and add 1 minute
  const arrivalTime = new Date(arrivalTimeStr);
  arrivalTime.setMinutes(arrivalTime.getMinutes() - 1);

  // Format the updated departureTime as an ISO string (you can format it based on the API requirements)
  const newArrivalTime = arrivalTime.toISOString();

  // Call fetchJourneys with the correct parameters
  fetchJourneys({
    type: 'initial',
    originId: originId,
    destinationId: destinationId,
    date: newArrivalTime,
    tripType: 'arrival', 
    options: {}, // If you have options, you can pass them here
    earlierThan: null, // No earlier journeys needed
    laterThan: null,  // No later journeys needed
    onJourneysFetched: (journeys, newEarlierThan, newLaterThan) => onJourneysFetched(journeys, newEarlierThan, newLaterThan, newArrivalTime),  // Pass the newArrivalTime to onJourneysFetched
    onError: onError,  // Callback for error handling
    onLoadingChange: onLoadingChange,  // Callback to show/hide loading indicator
  });
}

async function fetchTransferStats(firstLegLineName: string, firstLegDestinationId: string, secondLegLineName: string, secondLegOriginId: string) {
  try {
    const url = `https://api.chuuchuu.com/transferstats?firstLegLineName=${encodeURIComponent(firstLegLineName)}&firstLegDestinationId=${encodeURIComponent(firstLegDestinationId)}&secondLegLineName=${encodeURIComponent(secondLegLineName)}&secondLegOriginId=${encodeURIComponent(secondLegOriginId)}`;    
    
    return new Promise((resolve, reject) => {
      // Call your fetch logic here
      fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => resolve(data))
      .catch(error => {
        console.error('Fetch error:', error);
        reject(error);
      });
    });
  } catch (error) {
    console.error('Error fetching transfer stats:', error);
    return null;
  }
};

const JourneyDetailsScreen = ({ journey }: JourneyDetailsScreenProps) => {
  const [isLoading, setIsLoading] = useState(false); // State for button loading
  const [transferLoadingIndex, setTransferLoadingIndex] = useState<number | null>(null); // To track which transfer is loading
  const [legs, setLegs] = useState(journey.legs);
  const [originalLegs] = useState(journey.legs);
  const [isShowingAlternative, setIsShowingAlternative] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component is mounted
  }, []);
  
  const origin = legs[0].origin.name;
  const destination = legs[legs.length - 1].destination.name;
  const departure = new Intl.DateTimeFormat('en-GB', {
    weekday: "long",
    month: "numeric",
    day: "numeric",
  }).format(new Date(legs[0].departure))

  const fetchTransferStatsAndUpdateLegs = async (
    firstLeg: any,
    secondLeg: any,
    transferUpdateIndex: number,
    searchType: "departure" | "arrival"
  ) => {
    try {
      setTransferLoadingIndex(transferUpdateIndex); // Start loading
  
      const transferStats = await fetchTransferStats(
        firstLeg.line.name,
        firstLeg.destination.id,
        secondLeg.line.name,
        secondLeg.origin.id
      );
  
      if (transferStats) {
        console.log(transferStats);
  
        setLegs(currentLegs =>
          currentLegs.map((leg, idx) => {
            if (idx === transferUpdateIndex) {
              return {
                ...leg,
                transferStats
              };
            }
            return leg;
          })
        );
      }
    } catch (error) {
      console.error('Error fetching transfer stats:', error);
    } finally {
      setTransferLoadingIndex(null); // End loading
    }
  };

  // Define onJourneysFetched
  const onJourneysFetched = (
    journeys: any[], 
    newEarlierThan: string | null, 
    newLaterThan: string | null,
    newTime: string, // newDepartureTime or newArrivalTime
    searchType: "departure" | "arrival" // Determine search type
  ) => {
    // Filter journeys based on the first leg's departure time
    const filteredJourneys = journeys.filter(journey => {
      const firstLeg = journey.legs[0];
      const lastLeg = journey.legs[journey.legs.length - 1];

      if (searchType === "departure") {
        const firstLegDeparture = firstLeg.departure || firstLeg.plannedDeparture;
        return new Date(firstLegDeparture) >= new Date(newTime); // Filter later departures
      } else if (searchType === "arrival") {
        const lastLegArrival = lastLeg.arrival || lastLeg.plannedArrival;
        return new Date(lastLegArrival) <= new Date(newTime); // Filter earlier arrivals
      }
      return false;
    });

    if (filteredJourneys.length > 0) {
      // Determine whether to use the first or last journey based on searchType
      const newLegs = searchType === "departure"
        ? filteredJourneys[0].legs // Use the first journey for later departure
        : filteredJourneys[filteredJourneys.length - 1].legs; // Use the last journey for earlier arrival

      setLegs(currentLegs => {
        
        // Find the search point index in the original legs
        const searchPointIndex = searchType === "departure"
          ? currentLegs.findIndex(leg => leg.origin.id === newLegs[0].origin.id) // Compare origins for later departures
          : currentLegs.findIndex(leg => leg.destination.id === newLegs[newLegs.length - 1].destination.id); // Compare destinations for earlier arrivals
  
        // If the search point is found, combine legs
        if (searchPointIndex !== -1) {
          // Create the combined journey
          const combinedLegs = searchType === "departure"
            ? [
                ...currentLegs.slice(0, searchPointIndex), // Original legs up to the search point
                ...newLegs // New legs from the alternative journey (later departure)
              ]
            : [
                ...newLegs, // New legs from the alternative journey (earlier arrival)
                ...currentLegs.slice(searchPointIndex + 1) // Original legs after the search point
              ];
  
          setIsShowingAlternative(true);

          // For arrival searches, adjust the index to update the correct leg
          const transferUpdateIndex = searchType === "arrival" ? newLegs.length - 1 : searchPointIndex - 1;

          // Fetch transfer stats after updating legs
          const firstLeg = searchType === "departure" ? currentLegs[searchPointIndex - 1] : newLegs[newLegs.length - 1];
          const secondLeg = searchType === "departure" ? newLegs[0] : currentLegs[searchPointIndex + 1];

          if (firstLeg && secondLeg) {
            fetchTransferStatsAndUpdateLegs(firstLeg, secondLeg, transferUpdateIndex, searchType);
          }  

          return combinedLegs;  // Set combined legs as the new legs
        }
  
        return currentLegs; // Return original legs if no search point is found
      });
    }
  };

  // Define onError
  const onError = (message: string) => {
    console.error("Error fetching journeys:", message);
  };

  // Define onLoadingChange
  const onLoadingChange = (isLoading: boolean) => {
    setIsLoading(isLoading); // Toggle the loading state
  };

  const handleSearchClick = async (index: number, searchType: "departure" | "arrival") => {
    const nextLeg = trainLegs[index + 1];
    const prevLeg = trainLegs[index]; // Previous leg for earlier arrival
    
    try {
      if (searchType === "departure" && nextLeg) {
        const originId = nextLeg.origin.id;
        const destinationId = trainLegs[trainLegs.length - 1].destination.id;
        const plannedDeparture = nextLeg.plannedDeparture;
        const departure = nextLeg.departure;
    
        await fetchLaterDepartures( // Await if needed here
          originId,
          destinationId,
          plannedDeparture,
          departure,
          (journeys, newEarlierThan, newLaterThan, newDepartureTime) => 
            onJourneysFetched(journeys, newEarlierThan, newLaterThan, newDepartureTime, "departure"),
          onError,
          onLoadingChange
        );
        
      } else if (searchType === "arrival" && prevLeg) {
        const originId = trainLegs[0].origin.id; // Origin from first leg
        const destinationId = prevLeg.destination.id;
        const plannedArrival = prevLeg.plannedArrival;
        const arrival = prevLeg.arrival;
    
        await fetchEarlierArrivals(
          originId,
          destinationId,
          plannedArrival,
          arrival,
          (journeys, newEarlierThan, newLaterThan, newArrivalTime) => 
            onJourneysFetched(journeys, newEarlierThan, newLaterThan, newArrivalTime, "arrival"),
          onError,
          onLoadingChange
        );
      }
    } catch (error) {
      console.error("Error in search click handling:", error);
    }
  };

  const handleToggleJourney = () => {
    setLegs(isShowingAlternative ? originalLegs : legs);
    setIsShowingAlternative(!isShowingAlternative);
  };

  // Filter out walking legs
  const trainLegs = legs.filter(leg => !leg.walking);

  // Scroll to top on component mount
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <HeaderContainer>
        <PageHeader
        title={`${origin} ➜ ${destination}`}
        subtitle={`${departure}`}
        backButtonTitle="Journeys"
        />
      </HeaderContainer>
      <Container>
        <KPIsContainer journey={journey} />
        <LegsContainer>
          {trainLegs.map((leg, index) => (
            <React.Fragment key={index}>
              <JourneyLegCard leg={leg} />
                {index < trainLegs.length - 1 ? (
                  <>
                    <TransferContainer>
                      <AlternativesContainer>
                        <AlternativeButtons.EarlierArrivalButton
                          onClick={() => handleSearchClick(index, "arrival")}
                          isLoading={isLoading} 
                        />
                        {isShowingAlternative && !isLoading && (
                          <AlternativeButtons.OriginalJourneyButton 
                            onClick={handleToggleJourney} // Toggle back to the original journey
                          />
                        )}
                      </AlternativesContainer>

                      <Transfer 
                        index={index} 
                        journey={{ ...journey, legs: trainLegs }} 
                        isLoading={transferLoadingIndex === index}  // Pass the loading state
                      />     
                                    
                      <AlternativesContainer>
                        <AlternativeButtons.LaterDepartureButton
                          onClick={() => handleSearchClick(index, "departure")}
                          isLoading={isLoading} 
                        />
                        {isShowingAlternative && !isLoading && (
                          <AlternativeButtons.OriginalJourneyButton 
                            onClick={handleToggleJourney} // Toggle back to the original journey
                          />
                        )}
                      </AlternativesContainer>
                    </TransferContainer>
                  </>
                ) : null}
            </React.Fragment>
          ))}
        </LegsContainer>
        <FeedbackCard source={`Journey details from ${origin} to ${destination} on ${departure}`}/>
      </Container>
    </>
  )
}

export default JourneyDetailsScreen;
