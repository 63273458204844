import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { useNavigate, useLocation } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { LineStats } from './Types/lineStats';
import { DelayHistory } from './Types/delayHistory';
import { DropdownM } from '../Controls/Dropdowns';
import AvgDelayCard from './AvgDelayCard'
import DelayHistoryCard from './DelayHistoryCard';
import DistributionCard from './DelayDistributionCard';
import DelayEvolutionCard from './DelayEvolutionCard'
import FeedbackCard from '../FeedbackCard';



const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  box-sizing: border-box;
  align-items: left;
  gap: 32px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px 16px; // Add 24px padding at the top and bottom
  background: ${colors.white};
  border-bottom: 2px solid ${colors.keyHover};
`;

const DropdownContainer = styled('div')`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`


// Define the LineStatisticsResponse interface as an array of LineStats objects
type LineStatisticsResponse = LineStats[];

// Define a type for delay history response if different from LineStatisticsResponse
type DelayHistoryResponse = DelayHistory[];

const TrainStatistics = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation(); // Initialize useLocation
    const leg = location.state?.leg;

    console.log('Location State:', location.state);
    console.log('Received leg data:', leg);

    const [selectedOption, setSelectedOption] = useState<string>('destination');
    const [lineStatsData, setlineStatsData] = useState<LineStatisticsResponse | null>(null);
    const [delayHistoryData, setDelayHistoryData] = useState<DelayHistoryResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!leg) return;
    
        const stopId = selectedOption === 'origin' ? leg.origin.id : leg.destination.id;
    
        const fetchData = async () => {
          try {
            setLoading(true);
    
            // Construct both requests
            const lineStatsPromise = fetch(`https://api.chuuchuu.com/linestats?lineName=${encodeURIComponent(leg.line.name)}&stopId=${encodeURIComponent(stopId)}`);
            const delayHistoryPromise = fetch(`https://api.chuuchuu.com/delayhistory?lineName=${encodeURIComponent(leg.line.name)}&stopId=${encodeURIComponent(stopId)}`);
    
            // Await both promises
            const [lineStatsResponse, delayHistoryResponse] = await Promise.all([lineStatsPromise, delayHistoryPromise]);
    
            if (!lineStatsResponse.ok || !delayHistoryResponse.ok) {
              throw new Error('Network response was not ok');
            }
    
            // Parse both responses
            const lineStatsData: LineStatisticsResponse = await lineStatsResponse.json();
            const delayHistoryData: DelayHistoryResponse = await delayHistoryResponse.json();
    
            // Set state with the results
            setlineStatsData(lineStatsData);
            setDelayHistoryData(delayHistoryData);
          } catch (err) {
            if (err instanceof Error) {
              setError(err.message);
            } else {
              setError('An unknown error occurred');
            }
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, [leg, selectedOption]);

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };
    
    const handleBackClick = () => {
        const from = location.state?.from || '/';
        navigate(from); // Navigate back to the previous journey details page or the journeys overview if the state is missing
    };

    // Scroll to top on component mount
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

  return (
    <>
    <HeaderContainer>
      <PageHeader
        title={`${leg.line.name} statistics`}
        subtitle={`${leg.origin.name} ➜ ${leg.destination.name}`}
        backButtonTitle="Journey details"
        backButtonOnClick={handleBackClick} // Pass handleBackClick as prop if needed
      />
    </HeaderContainer>
    <Container>
        <DropdownContainer>
            <DropdownM value={selectedOption} onChange={handleDropdownChange}>
                <option value="origin">Departure at {leg.origin.name}</option>
                <option value="destination">Arrival at {leg.destination.name}</option>
            </DropdownM>
        </DropdownContainer>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error}</div>}
        {delayHistoryData && (
            <DistributionCard
                delayHistoryData={delayHistoryData} 
                selectedOption={selectedOption as 'origin' | 'destination'} 
            />
        )}
        {delayHistoryData && (
            <DelayEvolutionCard
                delayHistoryData={delayHistoryData} 
                selectedOption={selectedOption as 'origin' | 'destination'} 
            />
        )}
        {delayHistoryData && (
            <DelayHistoryCard 
                delayHistoryData={delayHistoryData} 
                selectedOption={selectedOption as 'origin' | 'destination'} 
            />
        )}
        {lineStatsData && (
            <AvgDelayCard data={lineStatsData} />
        )} 
        <FeedbackCard source={`${leg.line.name} statistics from ${leg.origin.name} to ${leg.destination.name}`} />
    </Container>
    </>
  );
};

export default TrainStatistics;