import { useState, useRef, useEffect } from "react";
import styled from "styled-components"
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { CTAButtonHollow } from "../Controls/Buttons";
import { TextField, MultiLineTextField } from "../Controls/TextFields";



const ContactContainer = styled("div")`
display: flex;
padding: 36px 34px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
align-self: stretch;
background: ${colors.keyBackground};
`

const FormContainer = styled("div")`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
`

const SuccessMessage = styled("div")`
display: flex;
padding: 16px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: ${colors.green300};
`

const ErrorMessage = styled("div")`
display: flex;
padding: 16px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: ${colors.red300};
`


const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isNameError, setIsNameError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [isUseCaseError, setIsUseCaseError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Create a reference to the ContactContainer
    const getAccessRef = useRef<HTMLDivElement>(null);

    const validateEmail = (email: string) => {
        // Simple regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
  
    const handleSubmit = async () => {
      let hasError = false;
  
      // Validate name field
      if (!name) {
        setIsNameError(true);
        hasError = true;
      } else {
        setIsNameError(false);
      }
  
      // Validate email field
      if (!email) {
        setEmailErrorMessage("This field is required");
        setIsEmailError(true);
        hasError = true;
      } else if (!validateEmail(email)) {
        setEmailErrorMessage("Please enter a valid email address");
        setIsEmailError(true);
        hasError = true;
      } else {
            setIsEmailError(false);
      }
  
      // Validate use case field
      if (!message) {
        setIsUseCaseError(true);
        hasError = true;
      } else {
        setIsUseCaseError(false);
      }
  
      if (!hasError) {
        // Prepare the data to send
        const formData = {
            name,
            email,
            message,
        };
  
      try {
        // Send a POST request to the server
        const response = await fetch('https://api.chuuchuu.com/send-contact-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          // Handle successful response
          const data = await response.json();
          console.log(data.message);
          setIsSuccess(true); // Set success state to true
          setName('');
          setEmail('');
          setMessage('');
          setErrorMessage("");  // Clear any previous error messages
        } else {
          // Handle error response
          const errorData = await response.json();
          console.error('Error:', errorData.message);
          setErrorMessage(errorData.message); // Set the error message to display
        }
      } catch (error) {
        console.error('Network error:', error);
        setErrorMessage("Network error. Please try again later."); // Set a network error message
      }
    }
  };

    // Scroll to top on component mount
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    return (
      <>
        <ContactContainer ref={getAccessRef}>
            <TypographyStyles.PageTitle color={colors.keyDarker}>Get in touch</TypographyStyles.PageTitle>
            <TypographyStyles.BodyLRegular color={colors.keyDarkest}>We’d love to hear from you. Simply fill out this form and we’ll get back to you in no time.</TypographyStyles.BodyLRegular>
            {!isSuccess ? ( // Conditionally render form or success message
                <>
                    <FormContainer>
                        <TextField
                            instruction="Name"
                            dynamicPlaceholder="e.g. John Delayhunter"
                            isDisabled={false}
                            isError={isNameError}
                            errorMessage="This field is required"
                            value={name}
                            isBackgroundDark={false}
                            onChange={(e) => {
                                setName(e.target.value);
                                if (isNameError) setIsNameError(false); // Reset error state on change
                            }}
                        />
                        <TextField
                            instruction="Email"
                            dynamicPlaceholder="e.g. jdelayhunter@email.com"
                            isDisabled={false}
                            isError={isEmailError}
                            errorMessage={emailErrorMessage}
                            value={email}
                            isBackgroundDark={false}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (isEmailError) setIsEmailError(false); // Reset error state on change
                            }}
                        />
                        <MultiLineTextField
                            instruction="Message"
                            dynamicPlaceholder="Write your message here"
                            isDisabled={false}
                            isError={isUseCaseError}
                            errorMessage="This field is required"
                            value={message}
                            isBackgroundDark={false}
                            onChange={(e) => {
                                setMessage(e.target.value);
                                if (isUseCaseError) setIsUseCaseError(false); // Reset error state on change
                            }}
                        />
                    </FormContainer>
                    <CTAButtonHollow onClick={handleSubmit}>Send</CTAButtonHollow>
                    {errorMessage && ( // Conditionally render ErrorMessage if there's an error
                        <ErrorMessage>
                            <TypographyStyles.BodyLSemibold color={colors.red600}>{errorMessage}</TypographyStyles.BodyLSemibold>
                        </ErrorMessage>
                    )}
                </>
            ) : (
                <SuccessMessage>
                    <TypographyStyles.BodyXLSemibold color={colors.green600} style={{ textAlign: 'center' }}>Request sent!</TypographyStyles.BodyXLSemibold>
                </SuccessMessage>
            )}
        </ContactContainer>
      </>
    );
};
  
export default ContactPage;