import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'; // Adjust the import path as needed
import { colors } from '../../utils/colors'


// Base TextButton with common styles
const BaseTextButton = styled.button`
  font-family: 'DrPublikSemibold';  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--Key, ${colors.key});
  leading-trim: both;
  text-edge: cap;
  cursor: pointer;
  background: none; /* Ensure the button has no background */
  border: none; /* Remove the default border */

  &:hover {
    color: var(--Key-darker, ${colors.keyDarker});
  }

  &:active {
    color: var(--Key-darkest, ${colors.keyDarkest});
  }

  &:disabled {
    color: ${colors.grey400};
    cursor: not-allowed;
  }
`;

// Specific TextButton sizes with typography styles
export const TextButtonXS = styled(BaseTextButton)`
  font-size: 12px;
`;

export const TextButtonS = styled(BaseTextButton)`
  font-size: 16px;
`;

export const TextButtonM = styled(BaseTextButton)`
  font-size: 24px;
`;

// Base Button with common styles
const BaseButton = styled.button`
  font-family: 'DrPublikSemibold';  
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 2px solid ${colors.key};
  background: ${colors.white};
  overflow: hidden;
  color: ${colors.key};
  leading-trim: both;
  text-edge: cap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    background: ${colors.keyBackground};
  }

  &:active {
    background: ${colors.keyHover};
  }

  &:disabled {
    background: none;
    border: 2px solid ${colors.grey400};
    cursor: not-allowed;
  }
`;

export const ButtonS = styled(BaseButton)`
  font-size: 16px;
`;

export const ButtonM = styled(BaseButton)`
  font-size: 24px;
  gap: 10px
`;

// Base CTA Button with common styles
const BaseCTAButton = styled.button`
  font-family: 'DrPublikSemibold'; 
  font-size: 28px; 
  display: flex;
  align-self: stretch;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  leading-trim: both;
  text-edge: cap;
  width: 100%; /* Make the button fill the available width */
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

export const CTAButtonFilled = styled(BaseCTAButton)`
  background: ${colors.key};
  color: var(--White, ${colors.white});
  border: none;

    &:hover {
    background: ${colors.keyDarker};
  }

  &:active {
    background: ${colors.keyDarkest};
  }

  &:disabled {
    background: ${colors.grey400};
    border: none;
`

export const CTAButtonHollow = styled(BaseCTAButton)`
  background: ${colors.white};
  color: var(--White, ${colors.key});
  border: 2px solid ${colors.key};

    &:hover {
    background: ${colors.keyBackground};
    color: var(--White, ${colors.keyDarker});
    border: 2px solid ${colors.keyDarker};
  }

  &:active {
    background: ${colors.keyHover};
    color: var(--White, ${colors.keyDarkest});
    border: 2px solid ${colors.keyDarkest};
  }

  &:disabled {
    background: ${colors.grey400};
    border: 2px solid ${colors.grey400};
`

const SegmentedControlContainer = styled.div`
  display: flex;
  justify-content: center;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px ${colors.keyDarkest};
  background: ${colors.keyBackground};
`;

const SegmentedControlButtonM = styled(ButtonM)<{ active: boolean }>`
  ${(props) =>
    !props.active &&
    `
    background-color: transparent;
    color: ${colors.keyDarkest};
    border: none;
  `}

  &:hover {
    background: none;
  }
`;

export const SegmentedControlM: React.FC<{
    activeValue: 'departure' | 'arrival';
    options: { label: string; value: 'departure' | 'arrival' }[];
    onChange: (value: 'departure' | 'arrival') => void;
  }> = ({ activeValue, options, onChange }) => {
    return (
      <SegmentedControlContainer>
        {options.map((option) => (
          <SegmentedControlButtonM
            key={option.value}
            active={activeValue === option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </SegmentedControlButtonM>
        ))}
      </SegmentedControlContainer>
    );
};

const SegmentedControlButtonS = styled(ButtonS)<{ active: boolean }>`
  ${(props) =>
    !props.active &&
    `
    background-color: transparent;
    color: ${colors.keyDarkest};
    border: none;
  `}

  &:hover {
    background: none;
  }
`;

export const SegmentedControlS: React.FC<{
    activeValue: 'departure' | 'arrival';
    options: { label: string; value: 'departure' | 'arrival' }[];
    onChange: (value: 'departure' | 'arrival') => void;
  }> = ({ activeValue, options, onChange }) => {
    return (
      <SegmentedControlContainer>
        {options.map((option) => (
          <SegmentedControlButtonS
            key={option.value}
            active={activeValue === option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </SegmentedControlButtonS>
        ))}
      </SegmentedControlContainer>
    );
};

// Default Close button
interface CloseButtonProps {
    onClick?: () => void;
    disabled?: boolean;
}

const CloseButtonBase = styled.button`
    width: 24px;
    height: 24px;
    flex-shrink: 0;  
    background: none;
    color: ${colors.grey800};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;

    &:hover {
    background: ${colors.grey200};
  }

  &:active {
    background: ${colors.grey300};
    color: ${colors.black};
  }

  &:disabled {
    background: none;
    color: ${colors.grey400};
  }

  svg {
    fill: currentColor; /* Ensure the SVG icon inherits the button text color */
  }
`

// Component that uses the styled button and accepts props
export const CloseButton: React.FC<CloseButtonProps> = ({ onClick, disabled }) => (
    <CloseButtonBase onClick={onClick} disabled={disabled}>
      <CrossIcon width={24} height={24} />
    </CloseButtonBase>
);