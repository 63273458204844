import React from 'react';
import { Journey } from "../../utils/mapJourneysData";
import transferIcon from '../../icons/transfericon.svg'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { colors } from "../../utils/colors";
import { getDurationInMinutes, formatDuration } from "../../utils/durationUtils";
import * as TypographyStyles from '../TypographyStyles';
import { getColor } from "../../utils/colorFormatter";
import { ReactSVG } from "react-svg";
import StatisticsBox from "../StatisticsBox";
import { TextButtonXS } from "../Controls/Buttons";


type TransferProps = {
  index: number;
  journey: Journey
  isLoading: boolean; // Accept the loading state
}

const TransferTimeContainer = styled('div')<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  grid-column-gap: 8px;
  & svg {
    fill: ${props => props.color};
    height: 14px; /* Set height to 14px */
    width: auto;  /* Maintain aspect ratio */
  }
` 

const BoxContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`

const StatisticsBody = styled('div')`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
`

type TransferTimeProps = { transferTime: number, type: string }

const TransferTime = ({ transferTime, type }: TransferTimeProps) => {
  const color = () => {
    if (type === 'planned') return colors.black
    if (type === 'estimated' && transferTime < 0) return colors.red
    return getColor(transferTime, 'transferTimeInMinutes')
  }

  return (
    <TransferTimeContainer color={color()}>
      <ReactSVG src={transferIcon} />
      <TypographyStyles.BodyMSemibold>
        {formatDuration(transferTime)}
        <TypographyStyles.BodyMRegular 
          color={transferTime < 0 && type === 'estimated' ? colors.red : colors.black}> {type} transfer time
        </TypographyStyles.BodyMRegular>
      </TypographyStyles.BodyMSemibold>
    </TransferTimeContainer>
  )
}

const Transfer = ({ index, journey, isLoading }: TransferProps) => {
  const navigate = useNavigate();
  
  // Ensure trainLegs is correctly filtered
  const trainLegs = journey.legs.filter(leg => !leg.walking);

  // Guard access to avoid out-of-bounds errors
  if (index >= trainLegs.length - 1) {
    return null; // Return nothing if index is out of bounds
  }

  const actualTransferStart = trainLegs[index].arrival;
  const actualTransferEnd = trainLegs[index + 1]?.departure;
  let TransferTimeComponent = null;

  if (actualTransferStart && actualTransferEnd) {
    const actualTransferTime = getDurationInMinutes(actualTransferStart, actualTransferEnd);
    const hasDelay = trainLegs[index]?.arrivalDelay && trainLegs[index + 1]?.departureDelay;
    TransferTimeComponent = () => <TransferTime transferTime={actualTransferTime} type={hasDelay ? 'estimated' : 'planned'} />;
  } else {
    const plannedTransferStart = trainLegs[index]?.plannedArrival;
    const plannedTransferEnd = trainLegs[index + 1]?.plannedDeparture;
    const plannedTransferTime = plannedTransferStart && plannedTransferEnd ? 
      getDurationInMinutes(plannedTransferStart, plannedTransferEnd) : 0;
    TransferTimeComponent = () => <TransferTime transferTime={plannedTransferTime} type="planned" />;
  }

  const transferStats = trainLegs[index].transferStats || {}; // Use the leg's transferStats if available
  const showTransferStats = !isLoading && (transferStats.averageTransferTime !== null || transferStats.percentageMissedConnections !== null);

  const handleSeeMoreClick = () => {
    console.log("Navigating to transfer statistics from:", window.location.pathname);
    navigate('/transfer-statistics', { state: { from: window.location.pathname, firstLeg: trainLegs[index], secondLeg: trainLegs[index+1] } });
  };

  return (
    <React.Fragment>
      {TransferTimeComponent && <TransferTimeComponent />}
        {showTransferStats ? (
          <StatisticsBox color={colors.grey300}>
            <BoxContent>
              <StatisticsBody>
              {isLoading ? (
                  <TypographyStyles.BodySRegular color={colors.grey800}>
                    <TypographyStyles.BodySSemibold size={12} weight={600} color={colors.grey600}>
                      ...
                    </TypographyStyles.BodySSemibold> real transfer time
                  </TypographyStyles.BodySRegular>
                ) : (
                  transferStats.averageTransferTime != null && (
                    <TypographyStyles.BodySRegular color={colors.grey800}>
                      <TypographyStyles.BodySSemibold size={12} weight={600} color={getColor(Math.round(transferStats.averageTransferTime), 'transferTimeInMinutes')}>
                        {formatDuration(Math.round(transferStats.averageTransferTime))}
                      </TypographyStyles.BodySSemibold> real transfer time
                    </TypographyStyles.BodySRegular>
                  )
                )}
                
                {isLoading ? (
                  <TypographyStyles.BodySRegular color={colors.grey800}>
                    <TypographyStyles.BodySSemibold size={12} weight={600} color={colors.grey600}>
                      ...
                    </TypographyStyles.BodySSemibold> missed transfers
                  </TypographyStyles.BodySRegular>
                ) : (
                  transferStats.percentageMissedConnections != null && (
                    <TypographyStyles.BodySRegular color={colors.grey800}>
                      <TypographyStyles.BodySSemibold color={getColor(Math.round(transferStats.percentageMissedConnections), 'missedTransfersPercentage')}>
                        {Math.round(transferStats.percentageMissedConnections)}%
                      </TypographyStyles.BodySSemibold> missed transfers
                    </TypographyStyles.BodySRegular>
                  )
                )}
              </StatisticsBody>
              <TextButtonXS onClick={handleSeeMoreClick}>See more</TextButtonXS>
            </BoxContent>
          </StatisticsBox>
        ) : null
      }
    </React.Fragment>  
  )
}

export default Transfer;