import React from 'react';
import styled from 'styled-components';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { DelayHistory } from './Types/delayHistory';

const ContainerCard = styled('div')`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const Chart = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
`;

const Cohort = styled.div`
display: flex;
align-items: center;
gap: 14px;
align-self: stretch;
`;

const HorizontalBar = styled.div`
display: flex;
height: 16px;
align-items: center;
flex: 1 0 0;
border-radius: 6px;
background: ${colors.grey300};
`;

interface BarFillProps {
    width: string;
    color: string;  // Add color prop
}

const BarFill = styled.div<BarFillProps>`
    height: 16px;
    border-radius: 6px;
    background-color: ${({ color }) => color};  // Use color prop for background color
    width: ${({ width }) => width};
`;

const CategoryText = styled(TypographyStyles.BodySRegular)`
color: ${colors.grey800};
width: 60px;
`;

const ValueText = styled(TypographyStyles.BodySRegular)`
color: ${colors.grey800};
width: 25px;
text-align: right;
`;


interface DelayDistributionCardProps {
    delayHistoryData: DelayHistory[];
    selectedOption: 'origin' | 'destination';

}

// Function to map category names to colors
const getBarColor = (label: string) => {
    switch (label) {
        case 'Early': return colors.grey800;
        case 'On time': return colors.green;
        case '5-9 min': return colors.orange;
        case '10-14 min': return colors.orange;
        case '15-29 min': return colors.red;
        case '30+ min': return colors.red;
        case 'Cancelled': return colors.red;
        default: return colors.grey300;  // Default in case of unexpected labels
    }
};

const DelayDistributionCard: React.FC<DelayDistributionCardProps> = ({ delayHistoryData, selectedOption }) => {

    // Define 7 buckets: Early, On time (0-4 min), 5-9 min, 10-14 min, 15-29 min, 30+ min, Cancelled
    const buckets = [0, 0, 0, 0, 0, 0, 0]; // Adding an additional bucket for 'Cancelled'

    delayHistoryData.forEach(entry => {
        const delay = selectedOption === 'origin' ? entry.departureDelay : entry.arrivalDelay;
        const cancelled = selectedOption === 'origin'
            ? entry.departureCancelled
            : entry.arrivalCancelled ?? entry.cancelled; // Fallback to entry.cancelled

        if (cancelled === true) {
            buckets[6] += 1;  // Count cancellations in the last bucket
        } else if (delay != null) {
            const delayInMinutes = Math.floor(delay / 60);

            if (delayInMinutes < 0) buckets[0] += 1;              // Early
            else if (delayInMinutes <= 4) buckets[1] += 1;        // On time (0-4 min)
            else if (delayInMinutes <= 9) buckets[2] += 1;        // 5-9 min
            else if (delayInMinutes <= 14) buckets[3] += 1;       // 10-14 min
            else if (delayInMinutes <= 29) buckets[4] += 1;       // 15-29 min (combined)
            else buckets[5] += 1;                                 // 30+ min
        }
    });

    const totalEntries = delayHistoryData.length;
    const percentages = buckets.map(count => (count / totalEntries) * 100);

    // Define new X-axis labels
    const xAxisLabels = [
        'Early',       // Negative delay
        'On time',     // 0-4 min
        '5-9 min',     // 5-9 min
        '10-14 min',   // 10-14 min
        '15-29 min',   // 15-29 min (combined)
        '30+ min',     // 30+ min
        'Cancelled'    // Cancelled
    ];

    // Only display the "Early" bucket if there are values in it
    const filteredPercentages = percentages[0] > 0 ? percentages : percentages.slice(1);
    const filteredLabels = percentages[0] > 0 ? xAxisLabels : xAxisLabels.slice(1);

    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>
                    Delay distribution
                </TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>
                    (last 30 days)
                </TypographyStyles.BodyLRegular>
            </CardHeader>
            <Chart>
                {filteredPercentages.map((percentage, index) => (
                    <Cohort key={index}>
                        <CategoryText>{filteredLabels[index]}</CategoryText>
                        <HorizontalBar>
                            <BarFill 
                                width={`${percentage}%`} 
                                color={getBarColor(filteredLabels[index])}  // Pass the label to determine the color
                            />
                        </HorizontalBar>
                        <ValueText>{percentage.toFixed(0)}%</ValueText>
                    </Cohort>
                ))}
            </Chart>
        </ContainerCard>
    );
};

export default DelayDistributionCard