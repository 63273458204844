import styled from "styled-components";
import { BodyMRegular, BodyMSemibold, BodyMBold } from './TypographyStyles';
import { colors } from "../utils/colors";
import DelayIndication from "./DelayIndication";
import { getColor } from "../utils/colorFormatter";
import { ReactSVG } from "react-svg";
import statsIcon from '../icons/statisticsIcon.svg'

const TimesContainer = styled('div')`
  display: flex;
  grid-column-gap: 8px;
  align-items: center;
`

const weightToComponent = {
  400: BodyMRegular,
  600: BodyMSemibold,
  700: BodyMBold,
};

interface DelayContainerProps {
  hasRealInfo: boolean; 
  containerColor: string;
}

const DelayContainer = styled('div')<DelayContainerProps>`
  color: ${props => props.hasRealInfo ? colors.grey600 : props.containerColor};
  display: flex;
  grid-column-gap: 4px;
  align-items: center;
  & svg {
    fill: ${props => props.hasRealInfo ? colors.grey600 : props.containerColor};
    width: 14px;
    height: 14px;
  }
`

// Utility function to format time as hh:mm
const formatTime = (time: string): string => {
  const [hours, minutes] = time.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

type TimeContainerProps = {
  time: string;
  delay?: number;
  averageDelay?: number;
  weight?: 400 | 600 | 700 ; // Restrict weight to valid values
}

const TimeContainer = ({ time, delay, averageDelay, weight = 700 }: TimeContainerProps) => { // Default weight to 700
  let formattedTime;
  
  try {
    // Attempt to format the time
    formattedTime = new Intl.DateTimeFormat('en-GB', { timeStyle: 'short' }).format(new Date(time));
  } catch {
    try {
      // Attempt to format the time with a dummy date
      formattedTime = formatTime(time);
    } catch {
      // Fallback to raw time if formatting fails
      formattedTime = time;
    }
  }
  
  const isOnTime = delay === 0;
  const TypographyComponent = weightToComponent[weight] || BodyMBold; // Fallback to BodyMRegular if weight is not valid
  
  return (
    <TimesContainer>
      <TypographyComponent color={isOnTime ? colors.green : colors.black} weight={weight}>
        {formattedTime}
      </TypographyComponent>      
      {delay ? <DelayIndication size={14} weight={700} delay={delay} /> : null}
      {averageDelay != null ? (
        <DelayContainer hasRealInfo={!!delay} containerColor={getColor(averageDelay, 'delayInMinutes')}>
          <ReactSVG src={statsIcon} />
          <DelayIndication delay={averageDelay} size={14} weight={400} color="inherit" />
        </DelayContainer>
      ) : null}
    </TimesContainer>
  )
}

export default TimeContainer;
