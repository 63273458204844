import styled from "styled-components";
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import { getColor } from "../../utils/colorFormatter";
import { StyledTooltip } from '../TooltipStyles';


interface ContainerProps {
  score: number;
  completeness: string;
}

const Container = styled.div<ContainerProps>`
  display: inline-flex;
  flex-direction: row;
  width: min-content;
  grid-column-gap: 10px;
  background-color: ${props => (props.completeness === 'partial' || props.completeness === 'null') ? colors.white : getColor(props.score, 'chuuchuuScore')};
  color: ${props => (props.completeness === 'partial' || props.completeness === 'null') ? getColor(props.score, 'chuuchuuScore') : colors.white};
  padding: 2px 10px;
  border-radius: 6px;
  border: ${props => (props.completeness === 'partial' || props.completeness === 'null') ? `2px solid ${getColor(props.score, 'chuuchuuScore')}` : 'none'};
`

type ReliabilityScoreBadgeProps = {
  score?: number | null;
  completeness: string;
}

const ReliabilityScoreBadge = ({ score, completeness }: ReliabilityScoreBadgeProps) => {
  const displayScore = score != null ? Math.round(score) : -1; // Handle both null and undefined
  const displayText = displayScore < 0 ? 'n/a' : `${displayScore}${completeness === 'partial' ? '*' : ''}`;
  
  // Tooltip content based on completeness
  const tooltipContent = completeness === 'partial' 
    ? "% chance that this journey will happen without any delays, cancellations, or missed transfers. *This score is incomplete because some trains in the journey don't have enough data."
    : "% chance that this journey will happen without any delays, cancellations, or missed transfers";
  
  return (
    <>
    <Container score={displayScore} completeness={completeness} data-tooltip-id={`reliability-${displayScore}`}>
      <TypographyStyles.KPIValueSmall>{displayText}</TypographyStyles.KPIValueSmall>
      <TypographyStyles.KPITitle>chuuchuu<br />score™</TypographyStyles.KPITitle>
    </Container>
    <StyledTooltip id={`reliability-${displayScore}`} place="top" content={tooltipContent} className="tooltip-styled">
    </StyledTooltip>
    </>
  )
}

export default ReliabilityScoreBadge;